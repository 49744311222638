import { AddRounded, LocationOnTwoTone } from '@mui/icons-material';
import { Button, Select } from '@sortlist-frontend/design-system';
import { useTracker } from '@sortlist-frontend/tracking';
import { useTranslation } from '@sortlist-frontend/translation/ssr';
import { isNonEmptyString, useWindowMessage, WindowMessageParams } from '@sortlist-frontend/utils';
import { useRouter } from 'next/router';
import { Fragment, useEffect, useMemo, useState } from 'react';

import { NavigationData } from '_components/layout/Layout';
import { SearchTopics } from '_components/SearchTopic/SearchTopics';
import { useSearchPages } from '_core/repos/public-api/page.repo';
import { useLanguagesTopics } from '_core/repos/public-api/topics.repo';
import { Domain } from '_types/public-api';

import { LayoutSwitch } from '../ContextualBar/LayoutSwitch/LayoutSwitch';
import { Sort } from '../ContextualBar/Sort/Sort';
import { getFilters } from './constants';
import { FiltersDrawer } from './FiltersDrawer';
import { FilterTag } from './FilterTag';
import { AgencyFilterKey, Filters, FitlerValue, Option, SelectedFilters } from './types';
import {
  filtersToGraphqlFilters,
  getActiveFilters,
  getUnselectedOptions,
  parseQueryValue,
  toggleFilterOption,
} from './utils';

const VISIBLE_FILTERS: AgencyFilterKey[] = ['ratings', 'budgets'];

type Props = {
  agencyCardsLayoutValue: string;
  navigationData: NavigationData;
  serviceTopicGraphqlId?: string;
};

export const OPEN_FILTERS_DRAWER = 'open-filters-drawer';
export const RESET_FILTERS = 'reset-filters';

type Action = typeof OPEN_FILTERS_DRAWER | typeof RESET_FILTERS;
type Data = {
  message?: string;
  addedToShortlist?: boolean;
};

type RedirectArgs = {
  targetUrl: string;
  searchParams: URLSearchParams;
  currentUrl: string;
};
export const useFiltersMessage = (params?: WindowMessageParams<Action, Data>) => useWindowMessage<Action, Data>(params);

export const FiltersBar = (props: Props) => {
  const { agencyCardsLayoutValue, navigationData, serviceTopicGraphqlId } = props;
  const router = useRouter();
  const { t } = useTranslation();
  const { locale, origin, domain } = navigationData;

  const [locationId, setLocationId] = useState<string | null>(null);

  const { data: languages } = useLanguagesTopics(locale, origin);

  const {
    data: searchedPage,
    isLoading: isPageLoading,
    refetch: refetchSearchedPage,
  } = useSearchPages({
    domain,
    topics: [locationId as string, serviceTopicGraphqlId as string, domain?.location?.id ?? ''],
    locale,
    baseURL: origin,
    enabled: isNonEmptyString(locationId) && domain != null && serviceTopicGraphqlId != null,
  });

  const filterOptions = useMemo(() => getFilters(locale, languages ?? [], t), [locale, languages, t]);

  const [filters, setFilters] = useState<Filters>(parseQueryValue(router.query.filters));
  const [open, setOpen] = useState(false);

  const [selectedFilters, setSelectedFilters] = useState<SelectedFilters>([]);

  const { track } = useTracker({ app: 'appPublic' });

  useEffect(() => {
    setSelectedFilters(getActiveFilters(filterOptions, filters));
  }, [filterOptions]);

  const redirect = (args: RedirectArgs) => {
    const { targetUrl, searchParams, currentUrl } = args;
    if (Object.keys(filters).length > 0) {
      searchParams.set('filters', JSON.stringify(filters));
      searchParams.delete('after');
      searchParams.delete('before');
      searchParams.delete('page');
    } else {
      searchParams.delete('filters');
    }
    track('longtailFiltersApplied', {
      url: currentUrl,
      targetUrl: targetUrl ?? currentUrl,
      locationChangedTo: currentUrl === targetUrl ? null : filters['location']?.id ?? '',
      filters: filtersToGraphqlFilters(
        filterOptions,
        filters,
        serviceTopicGraphqlId as string,
        domain as Domain,
        locale,
      ),
    });

    const query = searchParams.toString();
    const queryString = isNonEmptyString(query) ? `?${query}` : '';

    const nextUrl = `${targetUrl}${queryString}`;

    if (nextUrl !== window.location.href) {
      router.push(nextUrl);
    }
  };

  const applyFilter = async (filters: Filters) => {
    const url = new URL(window.location.href);
    const currentUrl = url.origin + url.pathname;
    const searchParams = url.searchParams;

    if (searchParams.get('filters') === JSON.stringify(filters)) return;
    const queryLocation = parseQueryValue(searchParams.get('filters') ?? undefined)['location'];

    if (queryLocation != null && filters['location'] == null) {
      if (domain == null) return;
      return setLocationId(domain.location.id);
    }

    if (filters['location'] != null && (queryLocation == null || filters['location'].id !== queryLocation.id)) {
      return setLocationId(filters['location'].id);
    }

    redirect({ targetUrl: currentUrl, searchParams, currentUrl });
  };

  useEffect(() => {
    if (locationId == null) return;

    const url = new URL(window.location.href);
    const currentUrl = url.origin + url.pathname;
    const searchParams = url.searchParams;

    const fetchData = async () => {
      const response = await refetchSearchedPage();
      const targetUrl = response?.data?.url;
      if (targetUrl == null) return;

      redirect({ targetUrl, searchParams, currentUrl });
      setLocationId(null);
    };

    fetchData();
  }, [locationId]);

  useEffect(() => {
    setSelectedFilters(getActiveFilters(filterOptions, filters));

    applyFilter(filters);
  }, [filters]);

  useWindowMessage({
    onReceiveMessage: (action) => {
      if (action === OPEN_FILTERS_DRAWER) {
        toggleDrawer(true)();
      }
      if (action === RESET_FILTERS) {
        setFilters({});
      }
    },
  });

  const handleFilterChange = (key: AgencyFilterKey, value: FitlerValue | null) => {
    if (value == null) {
      delete filters[key];

      setFilters({ ...filters });
      return;
    }

    setFilters({ ...filters, [key]: value });
  };

  const handleRemoveFilter = (key: AgencyFilterKey, id: string) => {
    const newValue = toggleFilterOption(filters, key, id);
    handleFilterChange(key, newValue);
  };

  const toggleDrawer = (isOpen: boolean) => () => {
    setOpen(isOpen);
  };

  return (
    <Fragment>
      <div className="container-lg py-8 layout-row layout-align-space-between-center hide-xs">
        <div className="layout-row layout-align-start-center gap-12 width-100 pr-40">
          <SearchTopics
            className="flex-25"
            page="directory"
            cta="filters"
            element="location"
            placeholder={t('longtail:agencyFilters.location.searchLocation')}
            prependComponent={
              <div className="bg-secondary-100 rounded-top-left-sm rounded-bottom-left-sm border-secondary-300 p-8 layout-column layout-align-center-center flex">
                <LocationOnTwoTone className="text-secondary-500 filter-icon" />
              </div>
            }
            extraTopicId={serviceTopicGraphqlId}
            navigationData={navigationData}
            type="location"
            onChange={(value) => {
              handleFilterChange('location', {
                id: (value as Option).value,
                label: (value as Option).label,
              });
            }}
            isLoading={isPageLoading}
          />
          {VISIBLE_FILTERS.map((filterKey) => {
            const filter = filterOptions[filterKey];
            if (filter?.type === 'boolean') return null;

            const unselectedOptions = getUnselectedOptions(filterOptions, filterKey, filters);

            return (
              <Select
                key={filter.id}
                prependComponent={
                  <div className="bg-secondary-100 rounded-top-left-sm rounded-bottom-left-sm border-secondary-300 p-8 layout-column layout-align-center-center flex">
                    {filter.icon}
                  </div>
                }
                placeholder={filter.title}
                options={unselectedOptions}
                onChange={(value) => {
                  const newValue = toggleFilterOption(filters, filterKey, (value as Option).value);
                  handleFilterChange(filterKey, newValue);
                }}
                value={null}
              />
            );
          })}
          <Button
            iconLeft={<AddRounded />}
            label={t('longtail:agencyFilters.moreFilters')}
            buttonStyle="secondary"
            buttonVariant="light"
            size="md"
            className="flex-none"
            onClick={toggleDrawer(true)}
          />
        </div>
        <LayoutSwitch agencyCardsLayoutValue={agencyCardsLayoutValue} />
      </div>
      {selectedFilters.length > 0 ? (
        <div className="container-lg layout-row layout-align-start-center layout-wrap gap-12 py-8 hide-xs">
          {selectedFilters.map((filter) => (
            <FilterTag
              key={`${filter.key}-${filter.id}`}
              id={filter.id}
              label={filter.label}
              filterKey={filter.key}
              isSelected={true}
              onToggle={() => handleRemoveFilter(filter.key, filter.id)}
              showCloseButton
            />
          ))}
          <Button
            label={t('longtail:agencyFilters.clear')}
            buttonStyle="secondary"
            buttonVariant="default"
            size="xxs"
            onClick={() => {
              setFilters({});
            }}
          />
        </div>
      ) : null}
      <div className="hide-gt-xs layout-row layout-align-space-between-center p-16 py-8 filters-bar-mobile bg-neutral-100 width-100 shadow-3">
        <Sort navigationData={navigationData} t={t} />
        <Button
          label={`${t('longtail:agencyFilters.title')} (${selectedFilters.length})`}
          buttonStyle="secondary"
          buttonVariant="light"
          size="md"
          className="flex-none"
          onClick={toggleDrawer(true)}
        />
      </div>
      {open ? (
        <FiltersDrawer
          navigationData={navigationData}
          filters={filters}
          setFilters={setFilters}
          setOpen={setOpen}
          serviceTopicGraphqlId={serviceTopicGraphqlId}
        />
      ) : null}
    </Fragment>
  );
};
