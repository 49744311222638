import { Button, Rating } from '@sortlist-frontend/design-system/ssr';
import { ImageSourceHelper } from '@sortlist-frontend/media';
import { useTracker } from '@sortlist-frontend/tracking';
import { TFunction } from '@sortlist-frontend/translation/server-only';
import { useIsVisible } from '@sortlist-frontend/utils';
import Image from 'next/image';
import { FC, MutableRefObject, useEffect, useRef, useState } from 'react';

import { DomainInfo } from '_backend/integration/domain-info';
import { CertifiedFlag } from '_components/common/flags/CertifiedFlag';
import { FeaturedFlag } from '_components/common/flags/FeaturedFlag';
import { VerifiedFlag } from '_components/common/flags/VerifiedFlag';
import { Obfuscate } from '_components/common/Obfuscate/Obfuscate';
import { NavigationData } from '_components/layout/Layout';
import { getUrlHelper } from '_components/layout/PrimaryNav/DesktopNav/utils';
import { Expertise } from '_core/repos/expertises.repo';
import { TrackingParamsCpc, useTrackWithoutContext } from '_core/repos/track.repo';
import { Agency, Page, PagesAgency } from '_types/public-api';

import {
  AgencyCompareCta,
  Badges,
  BudgetDisplay,
  LanguagesDisplay,
  LocationDisplay,
  SectorsDisplay,
  TeamSizeDisplay,
  WorksDisplay,
} from './components';
import { getFormattedRating } from './utils';

export type AgencyCardProps = {
  page: Page;
  t: TFunction;
  agency: Agency;
  data: PagesAgency;
  keyInArray: number;
  paid?: boolean;
  navigationData: NavigationData;
  appRouter?: boolean;
  expertises?: Expertise[];
};

/* eslint-disable sonarjs/cognitive-complexity */
export const AgencyCard = (props: AgencyCardProps) => {
  const { data, paid, page, t, navigationData, appRouter = false, expertises, keyInArray } = props;
  const agency = props?.agency?.attributes;
  const { origin, locale, resolvedUrl } = navigationData;
  const domainInfo = DomainInfo.getFromOrigin(origin);
  const getUrl = getUrlHelper(domainInfo, locale);

  const serviceName = page?.data?.attributes?.metadata?.service_name;
  const pageExpertiseId = page?.data?.attributes?.briefing_options?.expertise?.id;

  const addresses = [...new Set(agency?.addresses.map(({ address }) => address[locale]))];
  const sectors = [...new Set(agency?.sectors.map(({ name }) => name[locale]))];

  const hasReviews = agency != null && agency.reviews_count > 0;

  const rating = hasReviews ? getFormattedRating(agency.reviews_rating_total / agency.reviews_count) : 0;

  const agencyCardRef = useRef<HTMLDivElement>(null);
  const hadBeenInViewport = useRef(false);
  const { track } = useTracker({ app: 'appPublic' });
  const { mutateAsync: internalTrack } = useTrackWithoutContext(origin, `${origin}${resolvedUrl}`);

  const trackAgencyImpression = (pagesAgency: PagesAgency, agency: Agency['attributes'], keyInArray: number) =>
    track('agencyImpression', {
      agencySlug: agency?.slug,
      agencyName: agency?.name,
      agencyPlan: agency?.visibility_plan,
      position: paid === true ? keyInArray + 1 : pagesAgency?.attributes.position + 1,
      result_type: pagesAgency?.attributes.section.toLowerCase(),
      type: 'longtails',
    });

  useIsVisible({
    ref: agencyCardRef as unknown as MutableRefObject<Element>,
    onIsVisible: () => {
      if (hadBeenInViewport.current) return;
      hadBeenInViewport.current = true;
      const trackData: TrackingParamsCpc = {
        name: 'agencyImpression',
        url: location.href,
        agency_external_id: agency.external_id,
        position: paid ? keyInArray + 1 : data?.attributes.position + 1,
        result_type: data?.attributes.section,
      };
      internalTrack(trackData);
      trackAgencyImpression(data, agency, keyInArray);
    },
    rootMargin: '-100px', // track when we see first 100px of the card
  });

  return (
    <li className="list-reset">
      <article
        id={`agency-card-${agency.slug}-${paid ? 'featured' : 'normal'}`}
        className="agency-card-content border border-secondary-300 bg-neutral-100 rounded-xl shadow-2 cursor-pointer height-100 layout-column layout-align-space-between-center relative">
        <div ref={agencyCardRef} className="agency-info layout-column layout-align-start-center flex">
          <Image
            suppressHydrationWarning
            className="agency-logo rounded-md shadow-2 relative outline-none mt-24"
            src={ImageSourceHelper.fromUrl(agency?.logo ?? '/_img/default/default-image-square.svg', {
              width: 150,
              quality: 95,
            })}
            width={80}
            height={80}
            alt={agency.name}
            unoptimized
          />
          <div className="agency-name layout-column layout-align-center-center py-16">
            <div className="layout-row layout-align-start-center mt-8 gap-x-8">
              <p title={agency.name} className="line-clamp-2 bold flex-noshrink text-center h5">
                <Obfuscate
                  href={getUrl('agency.profile', { slug: agency.slug })}
                  target={'_blank'}
                  className="text-secondary-900 layout-row gap-x-8">
                  {agency.name}
                </Obfuscate>
              </p>
              <span className="layout-row layout-align-start-center flags gap-x-8">
                {agency.sponsored === true && <CertifiedFlag t={t} />}
                {agency.verified === true && <VerifiedFlag t={t} />}
                {paid === true && <FeaturedFlag t={t} />}
              </span>
            </div>

            <div className="agency-rating mt-8 layout-row layout-align-center-center ">
              {hasReviews ? <span className="bold h6 mr-4">{Math.trunc(rating * 10) / 10}</span> : null}
              <Rating direction={'row'} rate={rating} size={16} className="flex-none">
                {agency.reviews_count === 0 ? (
                  <span className="ml-8 text-secondary-500 small text-truncate" suppressHydrationWarning>
                    ({t('longtail:agencyCard.reviewsCount_zero', { count: agency.reviews_count })})
                  </span>
                ) : (
                  <span className="ml-8 text-secondary-500 small text-truncate" suppressHydrationWarning>
                    ({t('longtail:agencyCard.reviewsCount', { count: agency.reviews_count })})
                  </span>
                )}
              </Rating>
            </div>
            {agency.tagline != null ? (
              <p
                className="small medium text-center my-8 text-break-word"
                dangerouslySetInnerHTML={{ __html: agency.tagline }}></p>
            ) : null}
          </div>
        </div>

        <Badges
          agency={agency}
          t={t}
          data={data}
          expertises={expertises}
          paid={!!paid}
          pageExpertiseId={pageExpertiseId}
        />
        <div className="agency-main agency-main-info layout-align-space-between-start bg-secondary-100 rounded-bottom-xl border-top border-secondary-300 width-100 gap-y-16 gap-x-16">
          <WorksDisplay
            worksCount={data.attributes.works_count}
            serviceName={serviceName}
            t={t}
            appRouter={appRouter}
          />
          <LocationDisplay
            locationNames={addresses}
            metadata={page.data.attributes.metadata}
            t={t}
            appRouter={appRouter}
          />
          <BudgetDisplay
            budget={data.attributes.budget}
            serviceName={serviceName}
            t={t}
            appRouter={appRouter}
            locale={locale}
          />
          <SectorsDisplay sectors={sectors} t={t} appRouter={appRouter} />
          <LanguagesDisplay
            languages={agency.languages}
            t={t}
            appRouter={appRouter}
            locale={locale}
            mainLocale={agency.locale}
          />
          <TeamSizeDisplay teamSize={agency.team_size} t={t} appRouter={appRouter} />
          <div className="layout-row layout-align-start-center width-100 agency-card-cta">
            <AgencyCompareCta agency={agency} paid={!!paid} />
            <Button
              id={`agency-card-cta-${agency.slug}-${paid ? 'featured' : 'normal'}`}
              className="flex-100"
              buttonStyle="primary"
              buttonVariant={paid ? 'raised' : 'light'}
              size="md"
              label={
                paid && !!agency.website_url
                  ? t('longtail:featured.visitWebsite')
                  : t('longtail:agencyCard.buttons.profile')
              }
            />
          </div>
        </div>
      </article>
    </li>
  );
};
