'use client';

import { cx } from '@emotion/css';
import { EmojiEventsTwoTone, ThumbUpOffAltTwoTone, WorkspacePremiumTwoTone } from '@mui/icons-material';
import { ClampLines, Tooltip } from '@sortlist-frontend/design-system/ssr';
import { useIsFeatureActive } from '@sortlist-frontend/feature-flags';
import { TFunction } from '@sortlist-frontend/translation/server-only';
import dynamic from 'next/dynamic';

import { Expertise } from '_core/repos/expertises.repo';
import { Agency, PagesAgency } from '_types/public-api';

import { getIsTopAwarded, getRecommendedReviews } from './utils';

const FeatureFlagComponents = dynamic(
  () =>
    import('_features/longtail/sections/AgenciesPanel/AgencyCard/components/FeatureFlagComponents').then(
      (mod) => mod.FeatureFlagComponents,
    ),
  { ssr: false },
);

type Props = {
  agency: Agency['attributes'];
  t: TFunction;
  data: PagesAgency;
  expertises?: Expertise[];
  paid: boolean;
  pageExpertiseId?: number;
};

export const Badges = (props: Props) => {
  const { agency, t, data, expertises, paid, pageExpertiseId } = props;
  const { awards, description, reviews } = agency;

  const { description: serviceDescription } = data.attributes.agency_card_metadata ?? {};

  const { isActive: isExperienceDonutActive, variant: experienceLevelVariant } =
    useIsFeatureActive('experience-level-donut');

  const isTopAwarded = getIsTopAwarded(awards);

  const recommendedReview = getRecommendedReviews(reviews);
  const totalRecommendedReview = recommendedReview.length;

  const isRecommended = totalRecommendedReview > 1 && totalRecommendedReview < 5;
  const isHighlyRecommended = totalRecommendedReview >= 5;

  return (
    <div
      className={cx(
        isExperienceDonutActive ? 'layout-row layout-align-space-between-stretch' : 'layout-column',
        'width-100',
      )}>
      <div className="layout-column">
        {!isRecommended && !isHighlyRecommended && !isTopAwarded ? null : (
          <div className={'agency-main agency-badges width-100 layout-wrap layout-row gap-x-8 gap-y-8'}>
            {isRecommended ? (
              <Tooltip
                className="text-center"
                arrow={true}
                placement="top"
                title={t('longtail:agencyCard.recommendedTooltip_v2')}
                suppressHydrationWarning>
                <div className="layout-row layout-align-center-center px-8 py-4 border border-secondary-300 rounded-sm">
                  <ThumbUpOffAltTwoTone className="text-secondary-500" style={{ fontSize: '0.875rem' }} />
                  <span className="ml-4 small text-truncate flex" suppressHydrationWarning>
                    {t('longtail:agencyCard.recommended')}
                  </span>
                </div>
              </Tooltip>
            ) : null}

            {isHighlyRecommended ? (
              <Tooltip
                className="text-center"
                arrow={true}
                placement="top"
                title={t('longtail:agencyCard.highlyRecommendedTooltip_v2')}
                suppressHydrationWarning>
                <div className="layout-row layout-align-center-center px-8 py-4 border border-secondary-300 rounded-sm">
                  <WorkspacePremiumTwoTone className="text-secondary-500" style={{ fontSize: '0.875rem' }} />
                  <span className="ml-4 small text-truncate flex" suppressHydrationWarning>
                    {t('longtail:agencyCard.highlyRecommended')}
                  </span>
                </div>
              </Tooltip>
            ) : null}

            {isTopAwarded ? (
              <Tooltip
                className="text-center"
                arrow={true}
                placement="bottom"
                title={t('longtail:agencyCard.awardWinnerTooltip_v2')}
                suppressHydrationWarning>
                <div className="layout-row layout-align-center-center px-8 py-4 border border-secondary-300 rounded-sm">
                  <EmojiEventsTwoTone className="text-secondary-500" style={{ fontSize: '0.875rem' }} />
                  <span className="ml-4 small text-truncate flex" suppressHydrationWarning>
                    {t('longtail:agencyCard.topAwarded')}
                  </span>
                </div>
              </Tooltip>
            ) : null}
          </div>
        )}

        <div className="agency-main pt-0 agency-info-hide-card">
          <ClampLines
            className="small text-secondary-500 lh-2 hide show-gt-xs"
            seeMoreLessClassName="text-nowrap small"
            lines={isExperienceDonutActive ? 4 : 2}
            sanitizeCollapsedText={true}
            text={serviceDescription ?? description}
            showMoreLessText={true}
            seeLessText={t('common:clampLines.seeLess')}
            seeMoreText={t('common:clampLines.seeMore')}
          />
        </div>
      </div>
      <FeatureFlagComponents data={data} t={t} expertises={expertises} pageExpertiseId={pageExpertiseId} />
    </div>
  );
};
