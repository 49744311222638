'use client';

import { cx } from '@emotion/css';
import { FavoriteBorder, FavoriteTwoTone } from '@mui/icons-material';
import { Button, Size, SnackbarProps, Tooltip } from '@sortlist-frontend/design-system';
import { useTranslation } from '@sortlist-frontend/translation/ssr';
import { useEffect, useState } from 'react';

import { OPEN_SNACKBAR, useSnackbarMessage } from '_features/longtail/components/SnackbarProviderLight';
import { SearchFormData } from '_features/search/utils';

import { Sidebar } from '../Menu/Sidebar';
import { SnackbarContent } from '../SnackbarContent';
import { trackAgencyAdded, trackAgencyRemoved } from '../trackers';
import { compare, ComparedAgency, SingleAgency, uncompare } from '../utils';
import * as S from './styles';

export type CompareButtonProps = {
  absolute?: boolean;
  size?: Size;
  page: string;
  agency: SingleAgency;
  paid?: boolean;
  searchContext?: SearchFormData;
  className?: string;
  comparing: boolean;
  comparedAgencies: ComparedAgency[];
  showSnackbar?: (params: SnackbarProps) => void;
  onChange: (agencies: ComparedAgency[]) => void;
};

export const CompareButton = (props: CompareButtonProps) => {
  const {
    absolute = false,
    size = 'lg',
    page,
    agency,
    paid = false,
    searchContext,
    className,
    comparing = false,
    showSnackbar,
    onChange,
    comparedAgencies,
  } = props;

  const { t } = useTranslation(['common']);
  const [showVariant, setShowVariant] = useState<boolean>(false);
  const [menuOpened, setMenuOpened] = useState<boolean>(Boolean(false));
  const { slug } = agency;
  const type = paid ? 'featured' : 'normal';

  const [menuOpenedSnackBar, setMenuOpenedSnackBar] = useState(false);

  const { sendMessage: openSnackbar } = useSnackbarMessage();

  const triggerSnackbar = (addedToShortlist: boolean) => {
    if (showSnackbar != null) {
      showSnackbar({
        message: (
          <SnackbarContent
            setMenuOpened={(value) => {
              setMenuOpenedSnackBar(value);
            }}
            addedToShortlist={addedToShortlist}
          />
        ),
      });
    } else {
      openSnackbar(OPEN_SNACKBAR, {
        addedToShortlist,
      });
    }
  };

  const handleCompare = (e: MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    const newAgencies = compare({ slug, searchContext });
    if (newAgencies === false) {
      if (showSnackbar != null) {
        showSnackbar({ message: t('common:comparator.shortlistFull') });
      } else {
        openSnackbar(OPEN_SNACKBAR, {
          message: t('common:comparator.shortlistFull'),
        });
      }
      return;
    }
    onChange(newAgencies);
    trackAgencyAdded({ page, agencySlug: slug });
    triggerSnackbar(true);
  };

  const handleUncompare = (e: MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    onChange(uncompare({ slug }));
    trackAgencyRemoved({ page, agencySlug: slug });
    triggerSnackbar(false);
    setShowVariant(false);
  };

  // IMPORTANT: Do not use onClick for the compare button
  // It is used inside the AgencyCard where for performance reasons, we use vanilla JS events
  // stopPropagation which prevent the event from bubbling up to the parent, in this case the AgencyCard,
  // will not work when using React events in combinations with vanilla JS events

  useEffect(() => {
    const compareButton = document.getElementById(`compare-btn-${agency.slug}-${type}`);
    const uncompareButton = document.getElementById(`uncompare-btn-${agency.slug}-${type}`);

    compareButton?.addEventListener('click', handleCompare);
    uncompareButton?.addEventListener('click', handleUncompare);

    return () => {
      compareButton?.removeEventListener('click', handleCompare);
      uncompareButton?.removeEventListener('click', handleUncompare);
    };
  }, [comparing]);

  return (
    <S.ButtonContainer
      id={comparing ? `uncompare-btn-${agency.slug}-${type}` : `compare-btn-${agency.slug}-${type}`}
      className={cx('layout-row', className)}>
      {comparing ? (
        <Tooltip placement="top" title={t('common:comparator.removeFromShortlist')} arrow={true}>
          <Button
            fab={true}
            size={size}
            buttonStyle="secondary"
            buttonVariant="default"
            animation="ripple"
            onMouseOver={() => setShowVariant(true)}
            onMouseLeave={() => setShowVariant(false)}
            className="active"
            icon={<FavoriteTwoTone />}
            iconSize="md"
            id={`${page}-favorite-btn`}
          />
        </Tooltip>
      ) : (
        <Tooltip placement="top" title={t('common:comparator.addToShortlist')} arrow={true}>
          <Button
            fab={true}
            size={size}
            buttonStyle="secondary"
            buttonVariant="default"
            animation="ripple"
            onMouseOver={() => setShowVariant(true)}
            onMouseLeave={() => setShowVariant(false)}
            className={cx(showVariant && 'active')}
            icon={<FavoriteBorder />}
            iconSize="md"
            id={`${page}-add-to-shortlist-btn`}
          />
        </Tooltip>
      )}

      {menuOpened || menuOpenedSnackBar ? (
        <Sidebar
          opened={menuOpened || menuOpenedSnackBar}
          onClose={(value) => {
            setMenuOpened(value);
            setMenuOpenedSnackBar(value);
          }}
          comparedAgencies={comparedAgencies}
          handleUncompare={() => {}}
          briefingStarted={!menuOpenedSnackBar}
          singleAgency={agency}
        />
      ) : null}
    </S.ButtonContainer>
  );
};
