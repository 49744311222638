import { ArrowDropDownRounded } from '@mui/icons-material';
import { useTracker } from '@sortlist-frontend/tracking';
import { TFunction } from '@sortlist-frontend/translation/server-only';

import { DomainInfo } from '_backend/integration/domain-info';
import { Menu } from '_components/common/Menu/Menu';
import { NavigationData } from '_components/layout/Layout';

import { sortOptions } from './utils';

export type SortProps = {
  navigationData: NavigationData;
  t: TFunction;
};

export const Sort = (props: SortProps) => {
  const { t, navigationData } = props;
  const { sort, resolvedUrl, query: queryFromUrl, origin } = navigationData;
  const domainInfo = DomainInfo.getFromOrigin(origin);

  const { track } = useTracker({ app: 'appPublic' });

  const sortOptionsList = sortOptions(t, true);
  const selectedSortOption = sortOptionsList.find((option) => option.value === sort);

  const getSortUrl = (sortBy: string) => {
    const routeChunks = resolvedUrl?.split('?') || [];
    const asPath = routeChunks[0].replace('#content', '');

    const query = new URLSearchParams(queryFromUrl);
    query.set('sort', sortBy);
    query.delete('page');
    query.delete('after');
    query.delete('before');
    if (sortBy === 'position') {
      query.delete('sort');
      const queryParams = query.toString() ? `?${query.toString()}` : '';
      return `${domainInfo?.getOriginUrl()}${asPath}${queryParams}`;
    } else {
      return `${domainInfo?.getOriginUrl()}${asPath}?${query.toString()}`;
    }
  };

  return (
    <Menu
      dataTestId="sort-select"
      containerClassName="width-100 p-0"
      buttonClassName="width-100 p-0"
      buttonVariant="default"
      buttonStyle="secondary"
      label={
        <div className="rounded-sm layout-row width-100 layout-align-start-center">
          <div className="layout-column layout-align-center-center small text-secondary-500">
            {t('longtail:sortBy.title')}:
          </div>
          <span className="px-2 py-4 layout-row layout-align-start-center mx-4 small">
            {selectedSortOption?.label ?? t('longtail:sortBy.recommendedLabel')}
          </span>
          <ArrowDropDownRounded className="p" />
        </div>
      }
      showArrow={false}
      menuClassName={'width-100 sort-menu sort-menu-new p-8'}
      items={sortOptionsList.map((option) => ({
        label: (
          <div className="layout-row layout-align-start-center gap-x-8">
            {option.icon}
            <div className="layout-column py-4">
              <span className="medium small">{option.label}</span>
              <span className="text-wrap text-secondary-500" style={{ fontSize: 12 }}>
                {option.description}
              </span>
            </div>
          </div>
        ),
        className: 'sort-menu-item-new rounded-sm',
        dataTestid: option.value,
        href: getSortUrl(option.value),
        onClick: () => {
          track('longtailSortApplied', {
            url: window.location.href,
            sort: option.value,
          });
        },
        visible: true,
        hideArrow: true,
        hideDivider: true,
      }))}
    />
  );
};
