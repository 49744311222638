'use client';

import { FC } from 'react';

import { CompareButton } from '_components/Comparator/Button';
import { useComparedAgencies } from '_core/hooks/use-compared-agencies';
import { Agency } from '_types/public-api';

export type AgencyCompareCtaProps = {
  agency: Agency['attributes'];
  paid: boolean;
};

export const AgencyCompareCta: FC<AgencyCompareCtaProps> = (props) => {
  const { agency, paid } = props;
  const { comparedAgencies, setComparedAgencies } = useComparedAgencies();

  return (
    <CompareButton
      className="flex-xs agency-card-compare-button"
      page="profile-bottom"
      agency={{ logo: agency.logo as string, slug: agency.slug, name: agency.name }}
      searchContext={undefined}
      comparing={comparedAgencies.some((compared) => agency.slug === compared.slug)}
      onChange={setComparedAgencies}
      comparedAgencies={comparedAgencies}
      paid={paid}
    />
  );
};
